import React, { Component } from "react";

class TemperatureSensor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      latestTemperature: null
    };

    this.updateValues = this.updateValues.bind(this);
  }

  componentDidMount() {
    const { dp } = this.props;
    let _this = this;

    // a callback from DataProvider
    dp.subscribe(_this.updateValues);
  }

  componentWillUnmount() {
    const { dp } = this.props;
    let _this = this;

    dp.unsubscribe(_this.updateValues);
    this.updateValues = null;
  }

  updateValues(item) {
    const { sensorName } = this.props;

    if (
      item == null ||
      item.sensor == null ||
      item.sensor.name !== sensorName
    ) {
      return;
    }

    if (item.q.data !== null && item.q.data.length > 0) {
      const t = parseFloat(item.q.data[0].value).toFixed(1);
      this.setState({ latestTemperature: t });
    }
  }

  render() {
    const { caption } = this.props;
    const { latestTemperature } = this.state;
    if (latestTemperature === null || isNaN(latestTemperature)) return "";

    const tempText = `${latestTemperature} C`;

    return (
      <div>
        {caption}&nbsp;<b>{tempText}</b>
      </div>
    );
  }
}

export default TemperatureSensor;
