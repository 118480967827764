class ObjectLocationMap {
  constructor() {
    this.data = [];
  }

  add(obj, objName) {
    let d = { ...obj, name: objName };
    this.data.unshift(d);
  }

  // point is X, Y
  getObjByPoint(point) {
    for (let i = 0; i < this.data.length; i++) {
      if (this._isInsideRectangle(point, this.data[i].rect)) {
        return this.data[i];
      }
    }

    return null;
  }

  _isInsideRectangle(point, rect) {
    if (
      point.x >= rect.x0 &&
      point.x <= rect.x1 &&
      point.y >= rect.y0 &&
      point.y <= rect.y1
    ) {
      return true;
    }

    return false;
  }
}

export default ObjectLocationMap;
