import React, { Component } from "react";
import Board from "./Board.js";

const REFRESH_INTERVAL = 2000;

class ElectricBoardComponent extends Component {
  intervalID = 99;

  constructor(props) {
    super(props);
    this.readingValues = [];
    this.activeCharts = this.loadInitialActiveSensorNames();
    this.board = null;

    this.onSensorClickHandler = this.onSensorClickHandler.bind(this);

    try {
      this.intervalID = setInterval(async () => {
        this.refreshBoard();
      }, REFRESH_INTERVAL);
    } catch (e) {
      console.log(e);
    }
  }

  // load configuration from .env file
  loadInitialActiveSensorNames() {
    let res = process.env.REACT_APP_INITIAL_ACTIVE_CHARTS;

    if (res === undefined)
      return <div>No active charts</div>;

    return res.split(",");
  }

  refreshBoard() {
    if (this.board == null || this.readingValues == null) return;

    this.board.setBoardVariables(this.readingValues, this.activeCharts);
    this.board.refreshDataAndRedraw();
  }

  _toggleSensorOnBoard(sensorName) {
    for (let i in this.activeCharts) {
      if (this.activeCharts[i] === sensorName) {
        this.activeCharts.splice(i, 1);
        return;
      }
    }

    this.activeCharts.push(sensorName);
  }

  // when a sensor has been clicked
  onSensorClickHandler(sensorName) {
    this._toggleSensorOnBoard(sensorName);
    this.props.onActiveChartUpdate(this.activeCharts);
  }

  componentDidMount() {
    // TODO :
    this.props.onActiveChartUpdate(this.activeCharts);

    const { dp } = this.props;

    var canvas = this.refs.canvas;
    this.board = new Board(canvas, this.onSensorClickHandler);
    let _this = this;

    let updateValues = function(item) {
      if (item == null || item.sensor == null) return;

      // 0 index is the latest value
      const sensorName = item.sensor.name;
      const val = item.q.data[0];

      if (val == null) return;

      _this.readingValues[sensorName] = val;
    };

    // callback from DataProvider
    dp.subscribe(updateValues);
  }

  render() {
    return (
      <div className="electric-board">
        <canvas ref="canvas" width={580} height={806} />
      </div>
    );
  }
}

export default ElectricBoardComponent;
