export const IMG_SOLAR_POWER = "/images/solar_power.svg";
export const IMG_WIND_POWER = "/images/wind_power.svg";

export const _TXT_STRIDAC = "INVERTORS";
export const _TXT_CHARGER = "CHARGING CONTROLLER";
export const _TXT_SOLAR1_CAPTION = "Solar 1";
export const _TXT_SOLAR2_CAPTION = "Solar 2";
export const _TXT_SOLAR3_CAPTION = "Solar 3";
export const _TXT_WIND1_CAPTION = "Wind 1";

export const NAME_AMPER_METER_SENSOR = "a";
export const NAME_VOLT_METER_SENSOR = "v";
export const NAME_CELSIUS_METER_SENSOR = "c";

export const FONT_STYLE1 = "18px Arial";
export const FONT_STYLE2 = "14px Arial";
export const FONT_STYLE3 = "12px Arial";
export const FONT_STYLE4 = "10px Arial";
export const FONT_STYLE5 = "9px Arial";
