import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import { BrowserRouter, Route, Switch } from "react-router-dom";

import CurrentTime from "./services/CurrentTime.js";
import PowerPlant from "./views/PowerPlant.js";
import SensorList from "./views/SensorList.js";
import About from "./views/About.js";
import Balance from "./views/Balance.js";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";

class App extends Component {
  render() {
    // Internet Explorer 6-11
    let isIE = /*@cc_on!@*/ false || !!document.documentMode;

    if (isIE === true)
      return (
        <div>
          <h1>Battery Monitoring System</h1>
          <p>
            We are sorry, this application does not support Internet Explorer.
            We recommend Safari or Google Chrome.
          </p>
        </div>
      );

    return (
      <div id="adb-container">
        <Row>
          <Col xs={12}>
            <div className="page-header">
              <div className="adb-logo-wrapper">
                <div className="logosLeft">
                  <h1>Battery Monitoring System</h1>
                </div>

                <div className="logosRight">
                  <a href="https://www.he3dausa.com">
                    <img
                      src="images/he3da_logo.png"
                      className="he3da"
                      alt="HE3DA Batteries"
                    />
                    <img
                      src="images/Power_Orbital_logo_long.svg"
                      className="powerorbital"
                      alt="Power Orbital"
                    />
                  </a>
                  <a href="http://www.adbitech.com">
                    <img
                      src="images/adbitech_logo1.svg"
                      className="adbitech"
                      alt="Adbitech.com - Custom IT Solutions"
                    />
                  </a>
                </div>
              </div>

              <div className="adb-logo-wrapper">
                <div className="logosLeft">
                  <div className="adb-links-bar">
                    <a href="/">Home</a>
                    <a href="/sensors">Sensors</a>
                    <a href="/about">About</a>
                    {/* <a href="/balance">Energeticka bilance</a> */}
                  </div>
                </div>

                <div className="logosRight currentTime">
                  <CurrentTime />
                </div>
              </div>
            </div>
          </Col>
        </Row>

        <BrowserRouter>
          <Switch>
            <Route exact path="/" component={PowerPlant} />
            <Route path="/sensors" component={SensorList} />
            <Route path="/about" component={About} />
            <Route path="/balance" component={Balance} />
          </Switch>
        </BrowserRouter>

        <footer className="page-footer font-small">
          <div className="footer-copyright text-center py-3">
            {process.env.REACT_APP_PROJECT_SIGNATURE}{" "}
            {process.env.REACT_APP_PROJECT_VERSION}:
            <a href="http://www.adbitech.com"> www.adbitech.com</a>
          </div>
        </footer>
      </div>
    );
  }
}

export default App;
