import * as constants from "./ElectricBoardConstants";

class DrawingLibrary {
  constructor(canvas) {
    this.ctx = canvas.getContext("2d");
    this.m = {
      canvasWidth: this.ctx != null ? this.ctx.canvas.width : 0,
      margin: 1,
      marginBottom: 10
    };
    this.canvasWidth = this.m.canvasWidth;
    this.drawHeadlineImages();
  }

  drawHeadlineImages() {
    let c = this;
    var imgSolar = new Image();
    imgSolar.src = constants.IMG_SOLAR_POWER;

    var imgWind = new Image();
    imgWind.src = constants.IMG_WIND_POWER;

    const imgWidth = this.canvasWidth / 6;
    const imgHeight = imgWidth;

    this.currentY = imgHeight + c.m.margin;

    window.onload = function() {
      const leftMargin = c.m.margin + 20;
      const fourthWidth = c.canvasWidth / 4;
      c.ctx.drawImage(
        imgSolar, 
        leftMargin, 
        10, 
        imgWidth, 
        imgHeight
      );
      c.drawText(
        constants._TXT_SOLAR1_CAPTION,
        leftMargin + imgWidth / 2,
        imgHeight,
        constants.FONT_STYLE2,
        "center",
        "hanging"
      );

      c.ctx.drawImage(
        imgSolar,
        fourthWidth + leftMargin,
        10,
        imgWidth,
        imgHeight
      );
      c.drawText(
        constants._TXT_SOLAR2_CAPTION,
        leftMargin + fourthWidth + imgWidth / 2,
        imgHeight,
        constants.FONT_STYLE2,
        "center",
        "hanging"
      );

      c.ctx.drawImage(
        imgSolar,
        fourthWidth *2 + leftMargin,
        10,
        imgWidth,
        imgHeight
      );
      c.drawText(
        constants._TXT_SOLAR3_CAPTION,
        leftMargin + 2 *fourthWidth + imgWidth / 2,
        imgHeight,
        constants.FONT_STYLE2,
        "center",
        "hanging"
      );

      c.ctx.drawImage(
        imgWind,
        fourthWidth * 3 + leftMargin,
        0,
        imgWidth,
        imgHeight
      );
      c.drawText(
        constants._TXT_WIND1_CAPTION,
        leftMargin + 3 * fourthWidth + imgWidth / 2,
        imgHeight,
        constants.FONT_STYLE2,
        "center",
        "hanging"
      );
    };
  }

  drawText(text, x, y, font, align, baseline, fontColor) {
    if (!this.ctx) return;
    this.ctx.font = constants.FONT_STYLE1;
    //const textWidth = this.ctx.measureText(text).width;
    this.ctx.textAlign = "center";
    this.ctx.textBaseline = "middle";

    if (align != null) {
      this.ctx.textAlign = align;
    }

    if (baseline != null) {
      this.ctx.textBaseline = baseline;
    }

    if (font != null) {
      this.ctx.font = font;
    }
    let defaultFontColor = this.ctx.fillStyle;

    if (fontColor) {
      this.ctx.fillStyle = fontColor;
    }

    this.ctx.fillText(text, x, y);
    this.ctx.fillStyle = defaultFontColor;
  }

  beginCircuit(a, b) {
    if (!this.ctx) return;

    this.ctx.lineWidth = 2;
    this.ctx.strokeStyle = "#000";
    this.ctx.beginPath();
    this.x = a;
    this.y = b;
    this.d = 0;
    this.dx = 1;
    this.dy = 0;
    this.ix = this.x;
    this.iy = this.y;
    this.ctx.moveTo(this.x, this.y);
  }

  endCircuit(closeCircle) {
    if (!this.ctx) return;
    const { ix, iy } = this;
    if (closeCircle === true) this.ctx.lineTo(ix, iy);
    if (this.ctx) this.ctx.stroke();
  }

  turnAround() {
    this.turnClockwise();
    this.turnClockwise();
  }

  turnClockwise() {
    this.d++;
    if (this.d === 4) this.d = 0;

    this.dx = Math.round(Math.cos(1.570796 * this.d));
    this.dy = Math.round(Math.sin(1.570796 * this.d));
  }

  turnCounterClockwise() {
    this.d--;
    if (this.d === -4) this.d = 0;

    this.dx = Math.round(Math.cos(1.570796 * this.d));
    this.dy = Math.round(Math.sin(1.570796 * this.d));
  }

  drawBattery(color) {
    const { dx, dy } = this;
    const l = 20;

    const redrawBattery = function(x, y, dx, dy, l, ctx, color, rect) {
      if (!ctx) return;

      if (rect != null) {
        x = rect.x0;
        y = rect.y0 + l;

        //const point = {x: rect.x0, y: rect.y0};
        const width = rect.x1 - rect.x0;
        const height = rect.y1 - rect.y0;
        ctx.clearRect(x, y - l, width, height + 1);
      }
      ctx.stroke();
      ctx.beginPath();
      ctx.moveTo(x, y);
      const originalStrokeStyle = ctx.strokeStyle;
      const originalAlpha = ctx.globalAlpha;

      if (color != null) {
        ctx.strokeStyle = color;
        ctx.globalAlpha = 1;
      }

      const wireLength = (4 * l) / 5;

      // wire in
      x += dx * wireLength;
      y += dy * wireLength;
      ctx.lineTo(x, y);

      // center battery part
      ctx.moveTo(x + (l / 2) * dy, y + (l / 2) * dx);
      ctx.lineTo(x - (l / 2) * dy, y - (l / 2) * dx);
      x += (dx * l) / 2;
      y += (dy * l) / 2;
      ctx.moveTo(x + l * dy, y + l * dx);
      ctx.lineTo(x - l * dy, y - l * dx);
      ctx.moveTo(x, y);

      // wire out
      x += dx * wireLength;
      y += dy * wireLength;
      ctx.lineTo(x, y);

      ctx.stroke();
      ctx.beginPath();
      ctx.strokeStyle = originalStrokeStyle;
      ctx.globalAlpha = originalAlpha;

      return { x: x, y: y };
    };

    let rect = {};
    rect.x0 = this.x;
    rect.y0 = this.y - l;

    const endCoordinates = redrawBattery(
      this.x,
      this.y,
      dx,
      dy,
      l,
      this.ctx,
      color
    );

    if (!endCoordinates) {
      //console.log("endCoordinates is not defined properly");
      return;
    }

    this.x = endCoordinates.x;
    this.y = endCoordinates.y;
    this.ctx.moveTo(this.x, this.y);

    rect.x1 = this.x;
    rect.y1 = this.y + l;

    return {
      rect: rect,
      redraw: color =>
        redrawBattery(this.x, this.y, dx, dy, l, this.ctx, color, rect)
    };
  }

  drawRectangle(rect) {
    const point = { x: rect.x0, y: rect.y0 };
    const width = rect.x1 - rect.x0;
    const height = rect.y1 - rect.y0;

    this.ctx.globalAlpha = 1;
    this.ctx.fillStyle = "#FF0000";
    this.ctx.fillRect(point.x, point.y, width, height);
    this.ctx.globalAlpha = 1;
  }

  clearRectangle(rect) {
    const point = { x: rect.x0, y: rect.y0 };
    const width = rect.x1 - rect.x0;
    const height = rect.y1 - rect.y0;

    this.ctx.clearRect(point.x, point.y, width, height);
  }

  drawWire(l) {
    if (!this.ctx) return;

    const { dx, dy } = this;

    this.x += dx * l;
    this.y += dy * l;
    this.ctx.lineTo(this.x, this.y);
  }

  // THIS FUNCTION WORKS CORRECTLY ONLY IN HORIZONTAL WIRE DIRECTION FROM LEFT TO RIGHT
  drawDot(l) {
    if (!this.ctx) return;

    
    const initialX = this.x;
    const initialY = this.y;
    this.ctx.stroke();
    this.ctx.beginPath();

    const { dx, dy } = this;

    this.x += dx - this.ctx.lineWidth/2;
    this.y += dy;

    this.ctx.arc(
      this.x,
      this.y,
      l,
      0,
      2* Math.PI,
      false
    );

    this.ctx.fill();
    this.ctx.globalAlpha = 1;

    this.ctx.moveTo(initialX, initialY);
  }

  moveCursorTo(x, y) {
    this.ctx.moveTo(x, y);
  }

  getCursorPosition() {
    return { x: this.x, y: this.y };
  }

  drawAmperMeter(color) {
    return this._drawMeter("A", color);
  }

  drawVoltMeter(color) {
    return this._drawMeter("V", color);
  }

  drawInvertor(color) {
    return this._drawBox(210, 38, constants._TXT_STRIDAC, color);
  }

  drawController(color) {
    return this._drawController("CHARGING CONTROLLER", color, "10px Arial");
  }

  _drawMeter(text, color) {
    const { dx, dy } = this;
    const l = 20;

    let rect = {};
    rect.x0 = Math.abs(dx) === 1 ? this.x : this.x - l;
    rect.y0 = Math.abs(dy) === 1 ? this.y : this.y - l;

    this.y += dy * l;
    this.x += dx * l;

    const drawText = function(text, x, y, ctx) {
      ctx.font = "18px Arial";
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      ctx.fillText(text, x, y);
    };

    const drawCircle = function(x, y, dy, l, ctx, color, rect) {
      dy += 30;
      if (!ctx) return;

      // if specified then clear the area
      if (rect != null) {
        const width = rect.x1 - rect.x0;
        const height = rect.y1 - rect.y0;
        ctx.clearRect(x - l, y - l, width, height);
      }

      const originalFillStyle = ctx.fillStyle;

      if (color != null) {
        ctx.fillStyle = color;
        ctx.globalAlpha = 1;
      }

      ctx.arc(
        x,
        y,
        l,
        0,
        2* Math.PI,
        false
      );

      if (color != null) {
        ctx.fill();
        ctx.globalAlpha = 1;
      }

      ctx.fillStyle = originalFillStyle;
    };

    const redrawMeter = function(text, x, y, dx, dy, l, ctx, color, rect) {
      if (!ctx) return;
      ctx.beginPath();
      drawCircle(x, y, dy, l, ctx, color, rect);
      drawText(text, x, y, ctx);
      ctx.stroke();
    };

    // draw the meter
    if (this.ctx) this.ctx.stroke();

    const initialX = this.x;
    const initialY = this.y;

    //drawCircle(this.x, this.y, dy, l, this.ctx);
    redrawMeter(text, this.x, this.y, dx, dy, l, this.ctx, color);

    if (this.ctx) this.ctx.beginPath();

    this.y += dy * l;
    this.x += dx * l;

    if (this.ctx) this.ctx.moveTo(this.x, this.y);

    rect.x1 = Math.abs(dx) === 1 ? this.x : this.x + l;
    rect.y1 = Math.abs(dy) === 1 ? this.y : this.y + l;

    if (rect.x0 > rect.x1) {
      const t = rect.x1;
      rect.x1 = rect.x0;
      rect.x0 = t;
    }

    if (rect.y0 > rect.y1) {
      const t = rect.y1;
      rect.y1 = rect.y0;
      rect.y0 = t;
    }

    return {
      rect: rect,
      redraw: color =>
        redrawMeter(text, initialX, initialY, dx, dy, l, this.ctx, color, rect)
    };
  }

  _drawBox(w, h, text, color) {
    const { dx, dy } = this;

    let rect = {};
    rect.x0 = Math.abs(dx) === 1 ? this.x : this.x - w/2;
    rect.y0 = Math.abs(dy) === 1 ? this.y : this.y - h/2;

    this.y += dy * h/2;
    this.x += dx * w/2;

    const drawText = function(text, x, y, ctx) {
      ctx.font = constants.FONT_STYLE2;
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      ctx.fillText(text, x, y);
    };

    const drawBox = function(x, y, w, h, ctx, color, rect) {
      // dy += h;
      if (!ctx) return;

      // if specified then clear the area
      if (rect != null) {
        const width = rect.x1 - rect.x0;
        const height = rect.y1 - rect.y0;
        ctx.clearRect(x - w/2, y - h/2, width, height);
      }

      const originalFillStyle = ctx.fillStyle;

      if (color != null) {
        ctx.fillStyle = color;
        ctx.globalAlpha = 1;
      }

      ctx.rect(x-w/2, y-h/2, w, h);

      if (color != null) {
        ctx.fill();
        ctx.globalAlpha = 1;
      }

      ctx.fillStyle = originalFillStyle;
    };

    const redrawMeter = function(text, x, y, w, h, ctx, color, rect) {
      if (!ctx) return;
      ctx.beginPath();
      drawBox(x, y, w, h, ctx, color, rect);
      drawText(text, x, y, ctx);
      ctx.stroke();
    };

    // draw the meter
    if (this.ctx) this.ctx.stroke();

    const initialX = this.x;
    const initialY = this.y;

    redrawMeter(text, this.x, this.y, w, h, this.ctx, color);

    if (this.ctx) this.ctx.beginPath();

    this.y += dy * h/2;
    this.x += dx * w/2;

    if (this.ctx) this.ctx.moveTo(this.x, this.y);

    rect.x1 = Math.abs(dx) === 1 ? this.x : this.x + w/2;
    rect.y1 = Math.abs(dy) === 1 ? this.y : this.y + h/2;

    if (rect.x0 > rect.x1) {
      const t = rect.x1;
      rect.x1 = rect.x0;
      rect.x0 = t;
    }

    if (rect.y0 > rect.y1) {
      const t = rect.y1;
      rect.y1 = rect.y0;
      rect.y0 = t;
    }

    return {
      rect: rect,
      redraw: color =>
        redrawMeter(text, initialX, initialY, w, h, this.ctx, color, rect)
    };
  }

}

export default DrawingLibrary;
