import * as constants from "./ElectricBoardConstants";
import ObjectLocationMap from "./ObjectLocationMap";
import DrawingLibrary from "./DrawingLibrary";

const ACTIVE_SENSOR_BACKGROUND_COLOR = "#35ba43";
const HOVER_SENSOR_BACKGROUND_COLOR = "#d2f2d2";

const SOLAR_1_PREP = "s1";
const SOLAR_2_PREP = "s2";
const SOLAR_3_PREP = "s3";
const WIND_1_PREP = "w1";
const BATTERIES_1_PREP = "b1";


// Leave at "false" until this Warning and bug is resolved
//
// "Can’t call setState (or forceUpdate) on an unmounted component.
// This is a no-op, but it indicates a memory leak in your application.
// To fix, cancel all subscriptions and asynchronous tasks in the
// componentWillUnmount method"
// https://medium.freecodecamp.org/how-to-work-with-react-the-right-way-to-avoid-some-common-pitfalls-fc9eb5e34d9e
const ENABLE_CLICK_EVENTS = false;

class Board {
  constructor(canvas, onSensorClickHandler) {
    this.connectionY = 0;
    this.chargerY = 0;
    this.solarMeasurementY = 0;
    
    this.rectangleMap = new ObjectLocationMap();
    this.dl = new DrawingLibrary(canvas);
    this.yBelowHeadlineImages = this.dl.currentY;
    this.connectionHeight = 20;
    this.chargerHeight = 30;
    this.readingValues = [];
    this.activeCharts = [];
    this.drawBoard();
    this.hoveredInstrument = null;
    this.onSensorClickHandler = onSensorClickHandler;
    var _this = this;

    if (ENABLE_CLICK_EVENTS === true) {
      canvas.addEventListener("click", function(event) {
        const { offsetX, offsetY } = event;
        const point = { x: offsetX, y: offsetY };
        const obj = _this.rectangleMap.getObjByPoint(point);
        if (obj != null) {
          _this.onSensorClickHandler(obj.name);
        }
      });
    }

    // implements hover effect
    canvas.addEventListener("mousemove", function(event) {
      const { offsetX, offsetY } = event;
      const point = { x: offsetX, y: offsetY };
      const obj = _this.rectangleMap.getObjByPoint(point);

      if (obj != null) {
        if (
          _this.hoveredInstrument != null &&
          _this.hoveredInstrument.name !== obj.name
        ) {
          const hi = _this.hoveredInstrument;
          _this.hoveredInstrument = null;
          hi.redraw(_this._getBackroundColorForSensor(hi.name));
        }

        obj.redraw(HOVER_SENSOR_BACKGROUND_COLOR);

        _this.hoveredInstrument = obj;
      } else if (_this.hoveredInstrument != null && obj == null) {
        const hi = _this.hoveredInstrument;
        _this.hoveredInstrument = null;
        hi.redraw(_this._getBackroundColorForSensor(hi.name));
      }
    });
  }

  setBoardVariables(readingValues, activeCharts) {
    this.readingValues = readingValues;
    this.activeCharts = activeCharts;
  }

  _isSensorActive(sensorName) {
    for (let i in this.activeCharts) {
      if (this.activeCharts[i] === sensorName) return true;
    }
    return false;
  }

  _getBackroundColorForSensor(sensorName) {
    if (
      this.hoveredInstrument != null &&
      this.hoveredInstrument.name === sensorName
    )
      return HOVER_SENSOR_BACKGROUND_COLOR;

    if (this._isSensorActive(sensorName) && ENABLE_CLICK_EVENTS === true)
      return ACTIVE_SENSOR_BACKGROUND_COLOR;

    return null;
  }

  refreshDataAndRedraw() {
    const dl = this.dl;

    // clear area
    const rect = {
      x0: 0,
      y0: this.yBelowHeadlineImages + dl.m.marginBottom + 5,
      x1: dl.canvasWidth,
      y1: 850
    };
    dl.clearRectangle(rect);
    this.drawBoard();
  }

  drawConnectionWireLeft(x, y) {
    const dl = this.dl;
    const leftMargin = dl.m.margin + x;
    const width = 10;
    const height = 20;

    dl.beginCircuit(leftMargin, y);
    dl.turnAround();
    dl.drawWire(width);
    dl.turnCounterClockwise();
    dl.drawWire(height);
    dl.endCircuit(false);

    dl.currentY = dl.y;
  }

  drawConnectionWireRight(x, y) {
    const dl = this.dl;
    const leftMargin = dl.m.margin + x;
    const width = 10;
    const height = 20;

    dl.beginCircuit(leftMargin, y);
    dl.drawWire(width);
    dl.turnClockwise();
    dl.drawWire(height);
    dl.endCircuit(false);

    dl.currentY = dl.y;
  }

  drawChargingController(x, y) {
    const dl = this.dl;
    const leftMargin = dl.m.margin + x;
    const width = 120;

    dl.beginCircuit(leftMargin, y);
    dl.drawWire(width);
    dl.turnClockwise();
    dl.drawWire(this.chargerHeight); //height
    dl.turnClockwise();
    dl.drawWire(width);
    dl.endCircuit(true);

    dl.drawText(
      constants._TXT_CHARGER,
      leftMargin + width / 2,
      y + this.chargerHeight / 2,
      constants.FONT_STYLE5
    );

    dl.currentY = dl.y;
  }

  drawMiddleWiringMinus(h) {
    const dl = this.dl;
    const fourthWidth = dl.canvasWidth / 4;
    const leftMargin = dl.m.margin + 30;
    const sectionHeight = 40;

    dl.beginCircuit(leftMargin, h);
    dl.turnClockwise();
    dl.drawWire(sectionHeight);
    
    dl.turnAround();
    dl.drawWire(sectionHeight);
    dl.turnClockwise();
    dl.drawDot(3);
    dl.drawWire(fourthWidth);
    dl.drawDot(3);
    dl.drawWire(fourthWidth);
    dl.drawDot(3);
    dl.drawWire(fourthWidth);

    dl.endCircuit(false);

  }

  drawMiddleWiringPlus(h) {
    const dl = this.dl;
    const fourthWidth = dl.canvasWidth / 4;
    const leftMargin = dl.m.margin + 110;
    const sectionHeight = 40;
    const plusShift = 15;

    dl.currentY = dl.y + sectionHeight;

    dl.beginCircuit(leftMargin, h);
    dl.turnClockwise();
    dl.drawWire(plusShift);
    dl.turnCounterClockwise();
    
    dl.drawDot(3);
    dl.drawWire(fourthWidth);
    dl.drawDot(3);
    dl.turnCounterClockwise();
    dl.drawWire(plusShift);
    dl.turnAround();
    dl.drawWire(plusShift);
    dl.turnCounterClockwise();

    dl.drawWire(fourthWidth);
    dl.drawDot(3);
    dl.turnCounterClockwise();
    dl.drawWire(plusShift);
    dl.turnAround();
    dl.drawWire(plusShift);
    dl.turnCounterClockwise();

    dl.drawWire(fourthWidth);
    dl.turnCounterClockwise();
    dl.drawWire(plusShift);
    dl.turnAround();
    dl.drawWire(plusShift);
    dl.turnCounterClockwise();

    dl.turnAround();
    dl.drawWire(3 * fourthWidth);
    dl.turnCounterClockwise();
    dl.drawWire(plusShift + sectionHeight + plusShift);
    dl.turnCounterClockwise();
    dl.drawWire(3 * fourthWidth - 20);

    dl.endCircuit(false);

  }

  drawMeasurementStridac(h) {
    const dl = this.dl;
    const leftMargin = dl.m.margin + 10;
    const boxHeight = 30;
    const width = 565;

    dl.beginCircuit(leftMargin, h);
    // dl.drawController("red");
    dl.drawWire(width);
    dl.turnClockwise();
    dl.drawWire(boxHeight); //height
    dl.turnClockwise();
    dl.drawWire(width);
    dl.endCircuit(true);

    dl.drawText(
      constants._TXT_STRIDAC,
      leftMargin + width / 2,
      h + boxHeight / 2
    );

    // dl.currentY = dl.y;
  }

  drawMeasurementSolar(h, x, namePrepend) {
    const dl = this.dl;

    dl.beginCircuit(x, h);
    
    dl.turnClockwise();
    dl.drawWire(10);

    const voltUname = namePrepend + constants.NAME_VOLT_METER_SENSOR;
    const amperUname = namePrepend + constants.NAME_AMPER_METER_SENSOR;

    // solar or wind amper meter
    this.rectangleMap.add(
      dl.drawAmperMeter(this._getBackroundColorForSensor(amperUname)),
      amperUname
    );

    dl.drawWire(60);
    dl.turnAround();
    dl.drawWire(30);
    dl.turnClockwise();
    dl.drawDot(3);
    dl.drawWire(20);

    // solar or wind volt meter
    this.rectangleMap.add(
      dl.drawVoltMeter(this._getBackroundColorForSensor(voltUname)),
      voltUname
    );

    dl.drawWire(20);
    dl.drawDot(3);
    dl.turnClockwise();
    dl.drawWire(30);
    dl.turnAround();

    // lowest Y od the section
    dl.currentY = dl.y;

    // dl.drawWire(h);

    dl.drawWire(110);

    dl.endCircuit(false);

    dl.drawText(
      this.parseReadingValue(amperUname, "A", 2),
      x + 22,
      h + 30,
      constants.FONT_STYLE3,
      "left",
      "bottom"
    );

    dl.drawText(
      this.parseReadingValue(voltUname, "V", 2),
      x + 25,
      h + 60,
      constants.FONT_STYLE3,
      "left",
      "bottom"
    );
  }

  parseReadingValue(sensorIdentifier, unit, roundDecimalPos) {
    let val = "";
    if (sensorIdentifier in this.readingValues) {
      if (
        this.readingValues[sensorIdentifier] != null &&
        this.readingValues[sensorIdentifier].value != null
      ) {
        val = parseFloat(this.readingValues[sensorIdentifier].value).toFixed(roundDecimalPos);
        val += " " + unit;
      }
    }

    return val;
  }

  drawInvertors(h) {
    const dl = this.dl;
    const leftMargin = dl.m.margin + 30;

    dl.beginCircuit(leftMargin, h);
    dl.drawDot(3);
    dl.drawWire(150);
    dl.turnClockwise();
    dl.drawWire(10);
    dl.turnCounterClockwise();
    dl.drawInvertor();
    dl.endCircuit(false);

    dl.beginCircuit(leftMargin+80, h+20);
    dl.drawDot(3);
    dl.drawWire(70);
    dl.endCircuit(false);

    dl.beginCircuit(leftMargin+360, h);
    dl.drawWire(40);
    dl.endCircuit(false);

    dl.beginCircuit(leftMargin+360, h+20);
    dl.drawWire(40);
    dl.endCircuit(false);

    dl.drawText("~ AC 120V", leftMargin+430, h+6, constants.FONT_STYLE2, "center", "hanging");
  }

  drawBatteryFieldArray(h, x) {
    const dl = this.dl;
    const harnessWireLength = 20;

    // draw main ampermeter
    dl.beginCircuit(x, h);
    let nodeBatteryArrTopLeftPoint = dl.getCursorPosition();
    dl.turnClockwise();
    dl.drawWire(20);

    const uname = BATTERIES_1_PREP + constants.NAME_AMPER_METER_SENSOR;

    this.rectangleMap.add(
      dl.drawAmperMeter(this._getBackroundColorForSensor(uname)),
      uname
    );
    dl.drawWire(20);
    dl.turnCounterClockwise();
    dl.drawDot(3);
    let nodeBatteryVoltmeterLeftPoint = dl.getCursorPosition();
    let nodeTopLeftBatteryArrPoint = dl.getCursorPosition();
    let nodeBottomRightBatteryArrPoint = null;
    dl.endCircuit(false);

    dl.drawText(
      this.parseReadingValue(uname, "A", 2),
      x + 25,
      h + 47,
      constants.FONT_STYLE3,
      "left",
      "bottom"
    );

    nodeTopLeftBatteryArrPoint.y += 60;
    nodeTopLeftBatteryArrPoint.x += harnessWireLength;

    // batteries
    for (let i = 0; i < 6; i++) {
      const rowSpacing = 67;
      const rowLetter = String.fromCharCode(65 + i);

      dl.beginCircuit(
        nodeTopLeftBatteryArrPoint.x,
        nodeTopLeftBatteryArrPoint.y + i * rowSpacing
      );

      const sensorIdentifier =
        "r" + rowLetter + constants.NAME_AMPER_METER_SENSOR;

      if (i > 0 && i < 5) {
        dl.drawDot(3);
      }
      dl.drawWire(20);
      this.rectangleMap.add(
        dl.drawAmperMeter(this._getBackroundColorForSensor(sensorIdentifier)),
        sensorIdentifier
      );

      dl.drawText(
        "row " + rowLetter,
        dl.getCursorPosition().x + 3,
        dl.getCursorPosition().y,
        constants.FONT_STYLE3,
        "start",
        "bottom"
      );

      dl.drawText(
        this.parseReadingValue(sensorIdentifier, "A", 2),
        dl.getCursorPosition().x + 2,
        dl.getCursorPosition().y + 5,
        constants.FONT_STYLE3,
        "start",
        "hanging"
      );

      dl.drawWire(70);

      const spaceBetweenBatteries = 4;
      const countBatteries = 7;

      for (let j = 1; j <= countBatteries; j++) {
        let space = spaceBetweenBatteries;
        if (j + 1 === countBatteries) space *= 2;

        const uname = // battery is defined just by coordinates, A1v - D7v
           rowLetter + j + constants.NAME_VOLT_METER_SENSOR;

        const unameBatteryTemperature = rowLetter + j + constants.NAME_CELSIUS_METER_SENSOR;

        this.rectangleMap.add(
          dl.drawBattery(this._getBackroundColorForSensor(uname)),
          uname
        );

        dl.drawText(
          this.parseReadingValue(uname, "V", 2),
          dl.getCursorPosition().x - 20,
          dl.getCursorPosition().y - 19,
          constants.FONT_STYLE4,
          "middle",
          "bottom"
        );

        // temperature in blue font
        dl.drawText(
          this.parseReadingValue(unameBatteryTemperature, "C", 1),
          dl.getCursorPosition().x - 20,
          dl.getCursorPosition().y - 28,
          constants.FONT_STYLE5,
          "middle",
          "bottom",
          "#0000EE"
        );

        dl.drawWire(space);
      } // end for

      if (i > 0 && i < 5) {
        dl.drawDot(3);
      }
      dl.endCircuit(false);

      nodeBottomRightBatteryArrPoint = dl.getCursorPosition();
    }

    //battery field wire LEFT (inner)
    const bfInnerWireLength =
      nodeBottomRightBatteryArrPoint.y - nodeTopLeftBatteryArrPoint.y;
    dl.beginCircuit(nodeTopLeftBatteryArrPoint.x, nodeTopLeftBatteryArrPoint.y);
    dl.turnClockwise();
    dl.drawWire(bfInnerWireLength);
    dl.endCircuit(false);

    //battery field wire LEFT (outer) harness
    dl.beginCircuit(
      nodeBatteryVoltmeterLeftPoint.x,
      nodeBatteryVoltmeterLeftPoint.y
    );
    dl.turnClockwise();
    dl.drawWire(
      nodeTopLeftBatteryArrPoint.y -
        nodeBatteryVoltmeterLeftPoint.y +
        (nodeBottomRightBatteryArrPoint.y - nodeTopLeftBatteryArrPoint.y) / 2
    );
    dl.turnCounterClockwise();
    dl.drawWire(harnessWireLength);
    dl.drawDot(3);
    const nodeBatteryFieldHarnessLeftMidpoint = dl.getCursorPosition();
    dl.endCircuit(false);

    //battery field wire RIGHT (inner)
    dl.beginCircuit(
      nodeBottomRightBatteryArrPoint.x,
      nodeTopLeftBatteryArrPoint.y
    );
    dl.turnClockwise();
    dl.drawWire(bfInnerWireLength);
    dl.endCircuit(false);

    //battery field wire RIGHT (outer) harness
    dl.beginCircuit(
      nodeBottomRightBatteryArrPoint.x,
      nodeBatteryFieldHarnessLeftMidpoint.y
    );
    dl.drawDot(3);
    dl.drawWire(harnessWireLength);
    dl.turnCounterClockwise();
    dl.drawWire(nodeBatteryFieldHarnessLeftMidpoint.y - nodeBatteryArrTopLeftPoint.y - 45);
    dl.endCircuit(false);

    //draw main voltmeter
    dl.beginCircuit(
      nodeBatteryVoltmeterLeftPoint.x,
      nodeBatteryVoltmeterLeftPoint.y
    );
    const mainVoltmeterHalfWay =
      (nodeBottomRightBatteryArrPoint.x - nodeBatteryVoltmeterLeftPoint.x) / 2 -
      harnessWireLength / 2;
    dl.drawWire(mainVoltmeterHalfWay);

    const uname2 = BATTERIES_1_PREP + constants.NAME_VOLT_METER_SENSOR;

    this.rectangleMap.add(
      dl.drawVoltMeter(this._getBackroundColorForSensor(uname2)),
      uname2
    );
    dl.drawWire(mainVoltmeterHalfWay);
    dl.drawDot(3);
    dl.endCircuit(false);

    dl.drawText(
      this.parseReadingValue(uname2, "V", 2),
      nodeBatteryVoltmeterLeftPoint.x + mainVoltmeterHalfWay - 45,
      nodeBatteryVoltmeterLeftPoint.y,
      constants.FONT_STYLE3,
      "left",
      "bottom"
    );
  }

  drawBoard() {
    const dl = this.dl;
    const fourthWidth = dl.canvasWidth / 4;
    const marginLeft = dl.m.margin + 30;
    const connectionHeight = 20;

    this.connectionY = this.yBelowHeadlineImages + dl.m.marginBottom - 7;
    this.chargerY = this.connectionY + connectionHeight;
    this.solarMeasurementY = this.chargerY + this.chargerHeight;

    this.drawConnectionWireLeft(40, this.connectionY);
    this.drawConnectionWireRight(100, this.connectionY);
    this.drawConnectionWireLeft(40 + fourthWidth, this.connectionY);
    this.drawConnectionWireRight(100 + fourthWidth, this.connectionY);
    this.drawConnectionWireLeft(40 + 2 * fourthWidth, this.connectionY);
    this.drawConnectionWireRight(100 + 2 * fourthWidth, this.connectionY);
    this.drawConnectionWireLeft(40 + 3 * fourthWidth, this.connectionY);
    this.drawConnectionWireRight(100 + 3 * fourthWidth, this.connectionY);

    this.drawChargingController(10, this.chargerY);
    this.drawChargingController(10 + fourthWidth, this.chargerY);
    this.drawChargingController(10 + 2 * fourthWidth, this.chargerY);
    this.drawChargingController(10 + 3 * fourthWidth, this.chargerY);

    this.drawMeasurementSolar(
      this.solarMeasurementY, 
      marginLeft, 
      SOLAR_1_PREP
    );
    this.drawMeasurementSolar(
      this.solarMeasurementY,
      fourthWidth + marginLeft,
      SOLAR_2_PREP
    );
    this.drawMeasurementSolar(
      this.solarMeasurementY,
      2 * fourthWidth + marginLeft,
      SOLAR_3_PREP
    );
    this.drawMeasurementSolar(
      this.solarMeasurementY,
      3 * fourthWidth + marginLeft,
      WIND_1_PREP
    );

    this.drawMiddleWiringMinus(dl.currentY);
    this.drawMiddleWiringPlus(dl.currentY);
    // this.drawMeasurementStridac(dl.currentY);
    this.drawInvertors(dl.currentY);

    this.drawBatteryFieldArray(dl.currentY, marginLeft);
  }
}

export default Board;
