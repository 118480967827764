import React, { Component } from "react";
import * as moment from "moment";
import "moment-timezone";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {
  ResponsiveContainer,
  LineChart,
  AreaChart,
  Area,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  ReferenceLine,
  Tooltip,
  Label
} from "recharts";

const IS_DEBUG = true;
const CONNECT_NULLS = !IS_DEBUG;

function formatXAxis(tickItem) {
  // If using moment.js
  return moment(tickItem)
    .tz(process.env.REACT_APP_PROJECT_TIMEZONE)
    .format("HH:mm:ss");
}

function formatXAxisHourOnly(tickItem) {
  // If using moment.js
  return (
    moment(tickItem)
      //.tz("Etc/UTC")
      .tz(process.env.REACT_APP_PROJECT_TIMEZONE)
      .format("HH")
  );
}

class CustomTooltip extends Component {
  render() {
    const { active } = this.props;

    if (active) {
      const { payload, label, unitSymbol } = this.props;
      let val = payload[0] && payload[0].value ? payload[0].value : null;
      val = `${val} ${unitSymbol}`;
      let t = formatXAxis(parseInt(label));

      return (
        <div className="adb-tooltip">
          {t}
          <br />
          <strong>{val}</strong>
        </div>
      );
    }

    return null;
  }
}

class ChartComponent extends Component {
  constructor(props) {
    super(props);

    var yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);

    this.state = {
      settings: null,
      sensor: null,
      data: null,
      serverDown: false,
      mode: "realtime",
      startDate: yesterday
    };

    this.onSensorInfoUpdate = this.onSensorInfoUpdate.bind(this);
    this.updateValues = this.updateValues.bind(this);

    this.handleHistoricalBtnClick = this.handleHistoricalBtnClick.bind(this);
    this.handleRealtimeBtnClick = this.handleRealtimeBtnClick.bind(this);
    this.handleChangeDatePicker = this.handleChangeDatePicker.bind(this);

    this.handleHistNext = this.handleHistNext.bind(this);
    this.handleHistPrev = this.handleHistPrev.bind(this); 
  }

  _getSettingsVariables() {
    const { dp, sensorName } = this.props;
    const sensor = dp.sensorInfoList[sensorName];

    if (!sensor) {
      console.log("Error getting sensor data from DataProvider.js ", sensor);
      return;
    }

    const getSensorUnit = function(sensor) {
      const o = {
        assign: function(a, b, c) {
          this.physicalQuantityHumanReadable = a;
          this.physicalQuantitySymbol = b;
          this.lineColor = c;
        }
      };
      switch (sensor.unit) {
        case "Amper":
          o.assign("Amper [A]", "A", "#e84037");
          break;
        case "Volt":
          o.assign("Voltage [V]", "V", "#35ba43");
          break;

        default:
          throw new Error("Invalid unit ");
      }

      return o;
    };

    const getYAxisDomainMinValue = function(sensor) {
      const n = sensor.lowestBound;
      return n == null ? "auto" : parseFloat(n);
    };

    const getYAxisDomainMaxValue = function(sensor) {
      const n = sensor.highestBound;
      return n == null ? "dataMax + 1" : parseFloat(n);
    };

    const sensorUnit = getSensorUnit(sensor);
    const yAxisDomainMinValue = getYAxisDomainMinValue(sensor);
    const yAxisDomainMaxValue = getYAxisDomainMaxValue(sensor);
    return { ...sensorUnit, yAxisDomainMinValue, yAxisDomainMaxValue };
  }

  updateValues(item) {
    const { sensorName } = this.props;

    if (
      item == null ||
      item.sensor == null ||
      item.sensor.name !== sensorName
    ) {
      return;
    }

    //console.log(item.q.data);

    this.setState({ data: item.q.data });
  }

  onSensorInfoUpdate(item) {
    this.setState({ settings: this._getSettingsVariables(), sensor: item });
  }

  componentDidMount() {
    const { dp, sensorName } = this.props;
    let _this = this;

    // callback from DataProvider
    dp.subscribe(_this.updateValues);
    dp.subscribeSensorInfoUpdate(_this.onSensorInfoUpdate, sensorName);
  }

  componentWillUnmount() {
    const { dp, sensorName } = this.props;
    let _this = this;

    dp.unsubscribeSensorInfoUpdate(_this.onSensorInfoUpdate, sensorName);
    dp.unsubscribe(_this.updateValues);

    this.onSensorInfoUpdate = null;
    this.updateValues = null;
  }

  handleChangeDatePicker(date) {

    this.setState({
      startDate: date
    }, () => this.fetchHistoricalDayData());

  }

  handleHistNext(e) {
    e.preventDefault();

    const d = this.state.startDate;
    if (d === null ) return;

    let dayAfter = d;
    dayAfter.setDate(dayAfter.getDate() + 1);

    this.setState({
      startDate: dayAfter
    }, () => this.fetchHistoricalDayData());
  }

  handleHistPrev(e) {
    e.preventDefault();

    const d = this.state.startDate;
    if (d === null ) return;

    let dayBefore = d;
    dayBefore.setDate(dayBefore.getDate() - 1);

    this.setState({
      startDate: dayBefore
    }, () => this.fetchHistoricalDayData());

  }

  async handleHistoricalBtnClick(e) {
    e.preventDefault();
    const { dp, sensorName } = this.props;
    let _this = this;
    dp.unsubscribeSensorInfoUpdate(_this.onSensorInfoUpdate, sensorName);
    dp.unsubscribe(_this.updateValues);
    this.setState({ mode: "historical" });
    this.fetchHistoricalDayData();
  }

  async fetchHistoricalDayData() {
    const { sensorName } = this.props;
    let d = this.state.startDate;
    const year = d.getFullYear();
    const month = d.getMonth() + 1;
    const day = d.getDate();

    const baseurl = "/battery-datacenter-api/api/v1/reading";
    const url = `${baseurl}/day/${year}/${month}/${day}/${sensorName}`;

    const response = await fetch(url);

    if (response.ok) {
      const data = await response.json();

      let qdata = [];

      for (let i = data.length - 1; i >= 0; i--) {
        let o = {
          unixts: parseInt(data[i].timestamp),
          value: data[i].value
        };

        if (o.value != null) o.value = o.value.toFixed(3);

        qdata.unshift(o);
      }

      this.setState({ data: qdata });
    }
  }

  handleRealtimeBtnClick(e) {
    e.preventDefault();
    const { dp, sensorName } = this.props;
    let _this = this;
    dp.subscribe(_this.updateValues);
    dp.subscribeSensorInfoUpdate(_this.onSensorInfoUpdate, sensorName);
    this.setState({ mode: "realtime" });
  }

  render() {
    if (this.state.serverDown) {
      return <div>Server Down</div>;
    }

    if (!this.state.sensor || !this.state.settings || !this.state.data)
      return <div>{this.props.sensorName}</div>;

    const { caption, communicationProtocol } = this.state.sensor;
    const { displayCharging } = this.props;
    const {
      physicalQuantityHumanReadable,
      physicalQuantitySymbol,
      lineColor,
      yAxisDomainMinValue,
      yAxisDomainMaxValue
    } = this.state.settings;
    let data = this.state.data;

    const chartTitle = caption;

    if (this.state.mode === "historical" && data !== null && data.length < 90) {
      data = null;
    }

    let lastValue = null;
    if (data !== null && data.length > 0 && data[0] !== null) lastValue = data[0].value;

    const statusCharging = lastValue < 0 ? "discharging" : "charging";

    const chargingStatusDisplay = displayCharging ? (
      <div className="chartChargingStatus">{statusCharging}</div>
    ) : (
      ""
    );

    const chartMargin = { top: 0, right: 0, left: 0, bottom: 0 };
    const interpolationType = "monotone";

    let xAxis;
    if (this.state.mode === "realtime") {
      xAxis = (
        <XAxis
          scale="auto"
          tickCount={3}
          allowDataOverflow={false}
          interval="preserveEnd"
          dataKey="unixts"
          domain={["minData", "maxData + 0"]}
          tickFormatter={unixTime => formatXAxis(unixTime)}
          type="number"
        />
      );
    } else {
      xAxis = (
        <XAxis
          scale="auto"
          tickCount={10}
          allowDataOverflow={false}
          interval="preserveEnd"
          dataKey="unixts"
          domain={["minData + 1", "maxData + 0"]}
          tickFormatter={unixTime => formatXAxisHourOnly(unixTime)}
          type="number"
        />
      ); // formatXAxisHourOnly
    }

    const yAxis = (
      <YAxis
        scale="linear"
        domain={[yAxisDomainMinValue, yAxisDomainMaxValue]}
        dataKey="value"
        type="number"
      >
        <Label
          value={physicalQuantityHumanReadable}
          angle={-90}
          position="insideLeft"
          opacity="0.6"
        />
      </YAxis>
    );

    const toolTip = (
      <Tooltip
        isAnimationActive={true}
        animationEasing="ease"
        animationDuration={200}
        content={<CustomTooltip unitSymbol={physicalQuantitySymbol} />}
      />
    );

    const cartesianGrid = (
      <CartesianGrid strokeOpacity="0.7" strokeDasharray="3 3" />
    );

    const voltChart = (
      <AreaChart data={data} margin={chartMargin}>
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={lineColor} stopOpacity={0.6} />
            <stop offset="95%" stopColor={lineColor} stopOpacity={0} />
          </linearGradient>
        </defs>
        {xAxis} {yAxis}
        {cartesianGrid}
        {toolTip}
        <Area
          type={interpolationType}
          dataKey="value"
          stroke={lineColor}
          fillOpacity={1}
          fill="url(#colorUv)"
          connectNulls={CONNECT_NULLS}
          strokeWidth={2}
          isAnimationActive={false}
          animationDuration={0}
        />
      </AreaChart>
    );

    const amperChart = (
      <LineChart data={data} margin={chartMargin}>
        {xAxis}
        {yAxis}
        {cartesianGrid}
        <ReferenceLine y={0} stroke="black" />
        {toolTip}
        <Line
          type={interpolationType}
          connectNulls={CONNECT_NULLS}
          dataKey="value"
          dot={false}
          stroke={lineColor}
          strokeWidth={2}
          isAnimationActive={false}
          animationDuration={0}
        />
      </LineChart>
    );

    const noDataPlaceHolder = (
      <div className="no-data-place-holder">NO DATA AVAILABLE</div>
    );

    const realtimeStats = (
      <div>
        {chargingStatusDisplay}
        <div className="chartLastValueBox">
          {lastValue}&nbsp;{physicalQuantitySymbol}
        </div>
      </div>
    );

    const datePicker = (
      <div className="datePicker">
        <button onClick={this.handleHistPrev} >&lt;&lt;</button>
        <DatePicker
          maxDate={new Date()}
          selected={this.state.startDate}
          onChange={this.handleChangeDatePicker}
          filterDate={date => {
            return moment().subtract(1, "days") > date;
          }}
          placeholderText="Select a day"
          popperModifiers={{
            offset: {
              enabled: true,
              offset: "-45px, 0px"
            }
          }}
        />
        <button onClick={this.handleHistNext} >&gt;&gt;</button>
      </div>
    );

    return (
      <div className="adb-sensor-chart">
        <h4>{chartTitle}</h4>

        {this.state.mode === "historical" ? datePicker : ""}

        <div className="chartCommunicationProtocol">
          {data === null ? "" : communicationProtocol}
        </div>
        <div className="chartHistoricalRealtimeToggles">
          {this.state.mode === "historical" ? (
            <button onClick={this.handleRealtimeBtnClick}>
              SHOW REALTIME
            </button>
          ) : (
            <button onClick={this.handleHistoricalBtnClick}>
              SHOW HISTORICAL
            </button>
          )}
        </div>

        {this.state.mode === "realtime" ? realtimeStats : null}

        {data === null ? (
          noDataPlaceHolder
        ) : (
          <ResponsiveContainer width="100%" height="100%">
            {physicalQuantitySymbol === "V" ? voltChart : amperChart}
          </ResponsiveContainer>
        )}
      </div>
    );
  }
}

export default ChartComponent;
