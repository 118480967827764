import React, { Component } from "react";

import ChartComponent from "../components/charts/ChartComponent.js";
import ElectricBoardComponent from "../components/electric_board/ElectricBoardComponent.js";
import DataProvider from "../services/DataProvider.js";

//import AdbChart from "../components/charts/AdbChart";

import TemperatureSensor from "../components/temperature_sensor/TemperatureSensor";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";

class PowerPlant extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dp: new DataProvider(),
      activeCharts: []
    };

    this.handleActiveChartsUpdate = this.handleActiveChartsUpdate.bind(this);
  }

  async componentDidMount() {
    const { dp } = this.state;

    // TODO: this should come from an env file or the API
    const availableSensors = [
      "s1a",
      "s1v",
      "s2a",
      "s2v",
      "s3a",
      "s3v",
      "w1a",
      "w1v",
      "A1v",
      "A2v",
      "A3v",
      "A4v",
      "A5v",
      "A6v",
      "A7v",
      "B1v",
      "B2v",
      "B3v",
      "B4v",
      "B5v",
      "B6v",
      "B7v",
      "C1v",
      "C2v",
      "C3v",
      "C4v",
      "C5v",
      "C6v",
      "C7v",
      "D1v",
      "D2v",
      "D3v",
      "D4v",
      "D5v",
      "D6v",
      "D7v",
      "E1v",
      "E2v",
      "E3v",
      "E4v",
      "E5v",
      "E6v",
      "E7v",
      "F1v",
      "F2v",
      "F3v",
      "F4v",
      "F5v",
      "F6v",
      "F7v",

      "A1c",
      "A2c",
      "A3c",
      "A4c",
      "A5c",
      "A6c",
      "A7c",
      "B1c",
      "B2c",
      "B3c",
      "B4c",
      "B5c",
      "B6c",
      "B7c",
      "C1c",
      "C2c",
      "C3c",
      "C4c",
      "C5c",
      "C6c",
      "C7c",
      "D1c",
      "D2c",
      "D3c",
      "D4c",
      "D5c",
      "D6c",
      "D7c",
      "E1c",
      "E2c",
      "E3c",
      "E4c",
      "E5c",
      "E6c",
      "E7c",
      "F1c",
      "F2c",
      "F3c",
      "F4c",
      "F5c",
      "F6c",
      "F7c",

      "rAa",
      "rBa",
      "rCa",
      "rDa",
      "rEa",
      "rFa",
      "b1a",
      "b1v",
      "O1c",
      "O2c"
    ];

    availableSensors.map(sensor => dp.addSensor(sensor));
    dp.startAutoRefresh();
  }

  handleActiveChartsUpdate(ddd) {
    this.setState({ activeCharts: ddd });
  }

  render() {
    const { dp, activeCharts } = this.state;

    if (!activeCharts) {
      console.log("No active charts!");
      return;
    }

    const numberOfActiveCharts = activeCharts.length;

    let remainingChartsHTML = [];
    for (let i = 2; i < numberOfActiveCharts; i+=2) {
      remainingChartsHTML.push(
        <Row>
        <Col xs={12} lg={6}>
        <ChartComponent
          key={i}
          dp={this.state.dp}
          sensorName={activeCharts[i]}
        />
        </Col>
        <Col xs={12} lg={6}>
        <ChartComponent
          key={i+1}
          dp={this.state.dp}
          sensorName={activeCharts[i+1]}
        />
        </Col>
        </Row>
      );
    }

    return (
      <div className="page-content">
        
        <Row>
          <Col xs={12} lg={6}>
          <ElectricBoardComponent
            dp={dp}
            onActiveChartUpdate={this.handleActiveChartsUpdate}
          />
          </Col>

        <Col xs={12} lg={6}>
        <div className="adb-double-chart-container">

        <div id="outside-temperature-sensor">
          <TemperatureSensor
            dp={dp}
            sensorName="O1c"
            caption="Energy Storage Building Temperature"
          />
        </div>
        

          {numberOfActiveCharts > 0 ? (
            // <AdbChart id="1" dp={dp} sensorName={activeCharts[0]} displayCharging={true} />
            <ChartComponent
              id="1"
              dp={dp}
              sensorName={activeCharts[0]}
              displayCharging={true}
            />
          ) : null}
          {numberOfActiveCharts > 1 ? (
            <ChartComponent dp={dp} sensorName={activeCharts[1]} />
          ) : null}
        </div>
        </Col>
        </Row>

        {remainingChartsHTML}

        <hr />
      </div>
    );
  }
}

export default PowerPlant;
