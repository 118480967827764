class MyQueue {
  constructor(latestTime) {
    const windowSize = 60;
    const defaultValue = null;

    this.data = [];
    this.latestTime = latestTime;

    for (let i = 0, lt = latestTime; i < windowSize; i++, lt -= 1000) {
      this._unshiftOneRecord(defaultValue, lt);
    }
  }

  _unshiftOneRecord(value, unixts) {
    const o = { value, unixts };
    this.data.unshift(o);
  }

  // to be called only in edge cases
  // like loading a fresh dataset
  forceAdd(record) {
    this._unshiftOneRecord(record.value, Number(record.unixts));
    this.remove();
  }

  addEmpty() {
    const lt = Number(this.latestTime) + 1000;
    this._unshiftOneRecord(null, lt);
    this.latestTime = lt;
    this.remove();
  }

  add(record) {
    if (this.latestTime < record.unixts) {
      this.latestTime = Number(record.unixts);
      this.forceAdd(record);
    }
  }

  // fillEmpty() {
  //   // fill entire queue with nulls
  //   for (let i = 0; i < 60; i++) {
  //     this.addEmpty();
  //   }
  // }

  remove() {
    this.data.pop();
  }
  size() {
    return this.data.length;
  }
}

export default MyQueue;
