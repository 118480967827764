import React, { Component } from "react";

class SensorList extends Component {
  constructor() {
    super();

    this.state = {
      sList: []
    };
  }

  async fetchSensors() {
    const url = `/battery-datacenter-api/api/v1/sensor/all`;
    const response = await fetch(url);

    if (response.ok) {
      const data = await response.json();
      this.setState({ sList: data });
    }
  }

  componentDidMount() {
    this.fetchSensors();
  }

  render() {
    if (this.state.sList.length > 0) {
      return (
        <div className="page-content about">
          <h2>Sensor List</h2>
          <table width="100%">
            <tr>
              <th>Name</th>
              <th>Caption</th>
              <th>Description</th>
              <th>Unit</th>
              <th>Lowest Bound</th>
              <th>Highest Bound</th>
              <th>Protocol</th>
            </tr>

            {this.state.sList.map(function(n, index) {
              return (
                <tr>
                  <td>{n.name}</td>
                  <td>{n.caption}</td>
                  <td>{n.description}</td>
                  <td>{n.unit}</td>
                  <td>{n.lowestBound}</td>
                  <td>{n.highestBound}</td>
                  <td>{n.protocol}</td>
                </tr>
              );
            })}
          </table>
          <hr />
        </div>
      );
    } else
      return (
        <div className="page-content about">
          Loading Sensor List
          <img src="loader1.gif" alt="loader" className="imgloader" />
          <hr />
        </div>
      );
  }
}

export default SensorList;
