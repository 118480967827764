import React, { Component } from "react";

class About extends Component {
  render() {
    return (
      <div className="page-content about">
        <h2>HE3DA & POWER ORBITAL PILOT PROJECT IN GERLACH, NEVADA</h2>
        <p>
          Power Orbital introduces joint project of University of Nevada Las Vegas and HE3DA USA, who signed a strategic alliance agreement to participate
          on biological research in Great Boiling Spring, Gerlach, Nevada. UNLV is a global leader in the field of thermophilic bacteria. 
          HE3DA USA provides R&D support exlusively to Power Orbital.<br/>
          In 2018, HE3DA with Power Orbital built a pilot battery energy storage to support the formerly NASA funded biological research project.
          The new battery energy storage system was built at an off grid location in the middle of the desert to store wind and solar energy in order to provide 
          power to the entire ranch Camp David as well as test its batteries in extreme weather conditions (temperatures in the range of +40 °C to –20 °C.<br/>
          This pilot project proved the batteries are resilient, perform optimally in extreme conditions, requiring very low maintenance,
          therefore driving the operational cost down. This monitoring site shows batteries performing in a real time.
        </p>
        <p>
          Video summary of the project: <a href="https://youtu.be/IqDHqH9AkOw"> https://youtu.be/IqDHqH9AkOw</a>.
        </p>
        <p>
        <img
          src="images/location.jpg"
          className="location-photo"
          alt="Gerlach location satelite view"
        />
        <i>Gerlach pilot project location</i>
        </p>
        <br/>
        <p>
          Battery energy storage works completely independent on the monitoring system. <br/>
          When monitoring system is down for maintenance or due to an unexpected event such as Burning Man festival when all internet connections are down,
          whole power system still performs and provides uninterupted power supply to Camp David ranch. <br/>
          Internet connection in Camp David, rural Nevada ranch is rather slow and unreliable. If you notice that the connection is malfunctioning please
          contact us at <a href="mailto:info@adbitech.com">info@adbitech.com</a>. 
        </p>
        <p>
          Monitoring system for <a href="https://www.he3dausa.com">HE3DA</a> batteries is developed and serviced by company <a href="http://www.adbitech.com">Adbitech</a>.
        </p>

        <hr />
      </div>
    );
  }
}

export default About;
