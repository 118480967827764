import React, { Component, useState } from "react";
import DatePicker from "react-datepicker";
import * as moment from "moment";
import "moment-timezone";
// import "react-datepicker/dist/react-datepicker.css";

class Balance extends Component {
  constructor(props) {
    super(props);

    var yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);

    this.state = {
      startDate: yesterday.setHours(0, 0, 0),
      endDate: yesterday.setHours(23, 45, 0),
      powerIn: 0,
      powerOut: 0,
      powerTotal: 0,
      calculateButtonEnabled: true
    };
  }

  updatePickerStart(d) {
    let s = d.getTime();
    this.setState({ startDate: s, endDate: this.state.endDate });
    this.updatePickerChange();
  }

  updatePickerEnd(d) {
    let e = d.getTime();
    this.setState({ startDate: this.state.startDate, endDate: e });
    this.updatePickerChange();
  }

  updatePickerChange(d) {
    //
  }

  async componentDidMount() {
    // this.updateResText();
  }

  async fetchCalculationFromServer(fromTs, toTs) {
    if (fromTs > toTs) {
      alert("Invalid time combination!");
      return;
    }

    this.setState({ calculateButtonEnabled: false });

    let _this = this;
    const BASE_API_ENDPOINT = "/battery-datacenter-api/api/v1";
    const url = `${BASE_API_ENDPOINT}/reading/energeticbalance/compute/between/${fromTs}/${toTs}`;
    const response = await fetch(url);

    if (response.ok) {
      const data = await response.json();
      //console.log(data);
      this.setState({
        powerIn: data.powerIn,
        powerOut: data.powerOut,
        powerTotal: data.powerTotal,
        calculateButtonEnabled: true
      });
    }
  }

  convertFromBrowserTimezone(ms) {
    var localMoment = moment(ms);
    let targetMoment = moment.tz(
      localMoment.format("YYYY-MM-DDTHH:mm:ss"),
      "YYYY-MM-DDTHH:mm:ss",
      process.env.REACT_APP_PROJECT_TIMEZONE
    );
    return targetMoment.format("x");
  }

  handleCalculateClick(e) {
    const { startDate, endDate } = this.state;

    this.fetchCalculationFromServer(
      this.convertFromBrowserTimezone(startDate),
      this.convertFromBrowserTimezone(endDate)
    );
  }

  render() {
    const {
      powerIn,
      powerOut,
      powerTotal,
      calculateButtonEnabled,
      startDate,
      endDate
    } = this.state;

    const fromDatePicker = (
      <div className="datePicker-balance-page">
        <DatePicker
          selected={startDate}
          onChange={d => this.updatePickerStart(d)}
          showTimeSelect
          selectsStart
          startDate={startDate}
          endDate={endDate}
          timeFormat="HH:mm"
          timeIntervals={15}
          timeCaption="From"
          dateFormat="yyyy/MM/dd HH:mm"
          maxDate={endDate}
        />
      </div>
    );

    const toDatePicker = (
      <div className="datePicker-balance-page">
        <DatePicker
          selected={endDate}
          onChange={d => this.updatePickerEnd(d)}
          showTimeSelect
          selectsStart
          startDate={startDate}
          endDate={endDate}
          timeFormat="HH:mm"
          timeIntervals={15}
          timeCaption="Until"
          dateFormat="yyyy/MM/dd HH:mm"
          minDate={startDate}
          maxDate={new Date()}
        />
      </div>
    );

    const dataLoader = <div className="loader"></div>;

    const energyData = (
      <p className="energyData">
        <strong>Power input:</strong> {(powerIn / 60 / 1000).toFixed(2)}
        &nbsp;kWh
        <br />
        <strong>Power out:</strong> {(powerOut / 60 / 1000).toFixed(2)}&nbsp;kWh
        <br />
        <strong>Power total:</strong> {(powerTotal / 60 / 1000).toFixed(2)}
        &nbsp;kWh
        <br />
      </p>
    );

    return (
      <div className="page-content about">
        <div className="grid-container">
          <div className="grid-item full-width">
            <h3>Calculate energy balance</h3>
          </div>

          <div className="grid-item">
            From:
            <br />
            {fromDatePicker}
          </div>
          <div className="grid-item">
            To: <br />
            {toDatePicker}
          </div>
          <div className="grid-item full-width">
            <button
              onClick={e => this.handleCalculateClick(e)}
              disabled={!calculateButtonEnabled}
            >
              Calculate
            </button>
            <br />
            {calculateButtonEnabled ? energyData : dataLoader}
          </div>
        </div>
      </div>
    );
  }
}

export default Balance;
