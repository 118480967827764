import MyQueue from "../utils/MyQueue";

const REFRESHING_INTERVAL = 1000;
const TIMEOUT_INTERVAL = 5000;
const TEMPERATURE_SENSOR_TIMEOUT = 65000;
const FETCH_INITIAL_SENSOR_READING_MINUTE = true;

const BASE_API_ENDPOINT = "/battery-datacenter-api/api/v1";

class DataProvider {
  intervalID = 0;

  constructor() {
    this.sensorInfoList = [];
    this.sensorList = [];
    this.sensorDataList = [];

    this.handlers = []; // observers
    this.handlersSensorInfoUpdate = [];
    this.latestTimeStampSensorList = [];
  }

  startAutoRefresh() {
    try {
      this.intervalID = setInterval(async () => {
        this.fetchFreshReadingData();
      }, REFRESHING_INTERVAL);
    } catch (e) {
      console.log(e);
    }
  }

  subscribe(fn) {
    this.handlers.push(fn);
  }

  unsubscribe(fn) {
    this.handlers = this.handlers.filter(function(item) {
      if (item !== fn) {
        return item;
      }

      return null;
    });
  }

  subscribeSensorInfoUpdate(fn, sensorName) {
    const st = { fn: fn, sensorName: sensorName };
    this.handlersSensorInfoUpdate.push(st);

    // if there is an information for the given sensorName
    // when a client wants to subscribe, lets send them that information
    // immediately
    if (
      this.sensorDataList != null &&
      this.sensorDataList[sensorName] != null
    ) {
      this.fetchSensorInfoData(sensorName);
    }
  }

  unsubscribeSensorInfoUpdate(fn, sensorName) {
    const st = { fn: fn, sensorName: sensorName };
    this.handlersSensorInfoUpdate = this.handlersSensorInfoUpdate.filter(
      function(item) {
        if (item !== st) {
          return item;
        }

        return null;
      }
    );
  }

  fire(o, thisObj) {
    var scope = thisObj || window;
    this.handlers.forEach(function(item) {
      item.call(scope, o);
    });
  }

  fireSensorInfoUpdate(o, thisObj) {
    var scope = thisObj || window;
    this.handlersSensorInfoUpdate.forEach(function(item) {
      if (o.name === item.sensorName) {
        item.fn.call(scope, o);
      }
    });
  }

  addSensor(name) {
    for (let i in this.sensorList) {
      const n = this.sensorList[i];
      // if already there won't push another one
      if (n === name) return;
    }

    this.sensorList.unshift(name);

    this.latestTimeStampSensorList[name] = this.getCurrentTimestamp();

    this.fetchSensorInfoData(name);
    if (FETCH_INITIAL_SENSOR_READING_MINUTE === true)
      this.fetchInitialReadingSensorData(name);
  }

  removeSensor(name) {
    let index = -1;

    for (let i in this.sensorList) {
      const n = this.sensorList[i];
      if (n === name) {
        index = i;
        break;
      }
    }

    if (index === -1) {
      console.log(
        "Cannot remove this sensor. It is not the array anymore " + name
      );
      return;
    }

    this.sensorList.splice(index, 1);
  }

  async fetchSensorInfoData(sensorName) {
    let _this = this;

    if (_this.sensorDataList[sensorName] != null) {
      _this.fireSensorInfoUpdate(_this.sensorInfoList[sensorName]);
      return;
    }

    const url = `${BASE_API_ENDPOINT}/sensor/name/${sensorName}`;
    const response = await fetch(url);

    if (response.ok) {
      const data = await response.json();
      const sensorName = data.name;

      _this.sensorInfoList[sensorName] = data;
      _this.fireSensorInfoUpdate(_this.sensorInfoList[sensorName]);

      // intialize data queue
      _this.sensorDataList[sensorName] = new MyQueue(
        _this.latestTimeStampSensorList[sensorName]
      );
    } else {
      _this.setServerDown(_this);
    }
  }

  async fetchInitialReadingSensorData(sensorName) {
    let _this = this;
    let fetchVersionUrl = `/live/${sensorName}`;
    const url = `${BASE_API_ENDPOINT}/reading/minute${fetchVersionUrl}`;
    const response = await fetch(url);

    if (response.ok) {
      const data = await response.json();
      // apply force so that data don't get rejected
      // because of timestamp
      _this._loadInNewData(data, true, sensorName, _this);
    } else {
      _this.setServerDown(_this);
    }
  }

  async fetchFreshReadingData() {
    let _this = this;

    let maximalTS = _this.latestTimeStampSensorList[_this.sensorList[0]];

    for (let i = 1; i < this.sensorList.length; i++) {
      const sts = _this.latestTimeStampSensorList[_this.sensorList[i]];
      if (maximalTS < sts) {
        maximalTS = sts;
      }
    }

    // TODO: take a look at the -1 !!!
    // DON'T DELETE -1 OR EVERYTHING IS FUCKED UP!!!
    const latestTS = Math.floor(maximalTS / 1000) - 1;
    const url = `${BASE_API_ENDPOINT}/reading/minute/liveAfter-all/${latestTS}`;
    const response = await fetch(url);

    if (!response.ok) {
      this.setServerDown(_this);
      return;
    }

    const data = await response.json();

    // split the data per sensor
    const splitDataPerSensor = function(data) {
      var res = {};
      for (let i = 0; i < data.length; i++) {
        const item = data[i];
        const sensorName = item.sensorName;

        if (!(sensorName in res)) res[sensorName] = [];

        res[sensorName].unshift(item);
      }

      for (let sensorName in res) {
        res[sensorName].reverse();
      }
      return res;
    };

    const sdata = splitDataPerSensor(data);
    // cycle through the list of SUBSCRIBED sensors
    // we could also do "let sensorName in sdata"
    // but that would include cycling through sensors that
    // we might not be subsribed to
    for (let i in _this.sensorList) {
      const sensorName = _this.sensorList[i];

      _this._loadInNewData(sdata[sensorName], false, sensorName, this);
    }
  }

  _loadInNewData(data, isForceApply, sensorName, _this) {
    if (data != null && data.length > 0) {
      for (let i = data.length - 1; i >= 0; i--) {
        let o = {
          unixts: data[i].timestamp,
          value: data[i].value
        };

        // round up the value to 3 decimal points
        // elsewhere this datapoint may be further shrinked in decimal places
        if (o.value != null) o.value = o.value.toFixed(3);

        if (sensorName in _this.sensorDataList) {
          isForceApply
            ? _this.sensorDataList[sensorName].forceAdd(o)
            : _this.sensorDataList[sensorName].add(o);
        }
      }

      const ts = data[0].timestamp;

      if (ts > _this.latestTimeStampSensorList[sensorName]) {
        _this.latestTimeStampSensorList[sensorName] = ts;
      }
    } else {
      // if timeout has been greater than TIMEOUT_INTERVAL insert empty
      // const timeout =
      //   new Date().getTime() - _this.latestTimeStampSensorList[sensorName];

      const timeout =
        new Date().getTime() - _this.latestTimeStampSensorList[sensorName];

      if (timeout > TIMEOUT_INTERVAL && sensorName in _this.sensorDataList) {
        //   //var paragraph = 'D4c';
        //   var regex = /[A-Z][1-9]c/g;
        //   var found = cc.match(regex);
        // TEMPERATURE_SENSOR_TIMEOUT

        // temperature sensors will have greater timeout interval
        // is this sensor a temperature sensor? i.e A1c ... D7c
        let foundTempSensorName = sensorName.match(/[A-Z][1-9]c/g);
        if (foundTempSensorName) {
          if (timeout > TEMPERATURE_SENSOR_TIMEOUT) {
            _this.sensorDataList[sensorName].addEmpty();
          }
        } else {
          _this.sensorDataList[sensorName].addEmpty();
        }
      }
    }

    let res = {
      sensor: _this.sensorInfoList[sensorName],
      q: _this.sensorDataList[sensorName]
    };
    
    _this.fire(res);
  }

  getCurrentTimestamp() {
    // example we deal with
    //'2018-11-19 23:02:59.0'
    const actTime = new Date();
    return new Date(actTime).getTime();
  }

  setServerDown(_this) {
    console.log("Server going down!");
    clearInterval(_this.intervalID);
  }
}

export default DataProvider;
